@media (min-width: 1200px) {
    .container {
        max-width: 1300px !important;
    }
}

// Default XS media


.page-header {
    background-position: left;
}

.navbar.navbar-transparent {
    padding: 0;
}

@media (min-width: 576px) { 

 }

 @media (min-width: 768px) { 

}

@media (min-width: 992px) {

}

@media (min-width: 1200px) { 
    .navbar.navbar-transparent {
        padding: 20px;
    }
}

