.modal {

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 900px !important;
    }
  }

  .modal-header {
    display: flex !important;
  }

  .modal-footer {
    padding: 1rem;
  }

  h3, h4.description {
    color: #333333;
    font-weight: bolder;
  }

}
