.limehills-services {
    background-position: center center;
    background-size: contain;
    min-height: 58vh;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
        color: $primary-color;
        font-weight: bold;
    }

    .card {
        margin-top: 10px !important;
    }
}

.landing-section, .meet-the-team {
    h1 {
        font-size: 40px;
    }
}

.meet-the-team, .landing-section, .limehills-portfolio, .limehills-flow {
    h1 {
        color: $primary-color;
        font-weight: bold !important;
    }
}

.limehills-banner {
    background-position: center center;
    background-size: cover;
    min-height: 80vh;
    max-height: 999px;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.limehills-banner {
    background-image: "url(" + require("assets/img/limehills/limehills-con.png") + ")";
    background-repeat: no-repeat;
    // border-radius: 30px 200px;

    .container-fluid {
        .title {
            font-size: 55px;
            font-weight: bold;
            color: $white-color;
        }
    }
}

.contactus {
    background-position: center center;
    background-size: contain;
    min-height: 49vh;
    max-height: 999px;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}


.footer {
    background-color: #FBFBFB;
    padding-top: 40px;
    padding-bottom: 40px;

    .footer-logo {
        ul {
            display: flex;
            font-size: 20px;
            padding-left: 25px;
            padding-top: 9px;
            justify-content: center;

            li {
                margin-right: 7px;

                i {
                    color: $primary-color;
                    background-color: #F2F4F8;
                    border-radius: 25px;
                    height: 30px;
                    width: 30px;
                    font-size: 12px;
                    padding: 8px;
                }
            }
        }
    }

    img {
        height: 36px;
    }
    
    h5 {
        color: $primary-color;
        font-weight: 400;
        font-size: 20px;
    }

    ul {
        padding-inline-start: 0px;
        li {
            list-style: none;
            a {
                color: $primary-color;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }
}

.limehills-services {
    .card {
        .icon{
            font-size: 4em !important;
        }

        .card-description {
            color: $card-black-color;
            font-size: 1.2em;
        }

        .card-title{
            font-weight: 400;
        }

        background-color: $primary-color;
        .icon, .card-description, .card-title {
            color: $white-color;
        }
        
    }

    .card:hover{
        background-color: $primary-color;
        color: $primary-states-color;

        .icon, .card-description, .card-title {
            color: $white-color;
        }

    }
}

.intro-image {
    background-position: center center;
    background-size: contain;
    min-height: 42vh;
    max-height: 999px;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.limehills-services {
    .description {
        font-size: 1.3em !important;
    }
}