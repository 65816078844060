.limehills-portfolio {
    .card {
        width: 100%;
        height: 400px;
    }

    background-color: #FBFBFB;
}

/* Carousel Style Override */
.rec {

    :focus {
        outline: unset !important;
    }

    .rec-arrow {
        box-shadow: unset !important;
        border-radius: unset !important;
    }

    .rec-arrow:hover:enabled, .rec-arrow:focus:enabled{
        background-color: $primary-color !important;
    }

    .rec-arrow:active {
        box-shadow: unset !important;
        border: 0px;
    }

    .rec-dot {
        height: 12px;
    }
    .rec-dot_active{
        background-color: $primary-color !important;
        box-shadow: 0 0 1px 3px $primary-color !important;
    }
}

.rec .rec-pagination {
    margin-top: 0px !important;
}