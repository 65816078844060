.page-header {
    h1 {
        font-size: 2.5em;
    }
}

.section {
    .description {
        font-size: 1.8em;
        color: $black-color;
        font-weight: bolder;
    }
}