.navbar {
    
    background: $primary-color;

    .navbar-nav {
        .nav-item .nav-link {
            color: $white-color;
        }
    }

    .navbar-translate {
        img {
            height: 33px;
            position: relative;
            top: -2px
        }

        .navbar-brand {
            font-size: 22px;
            color: $white-color;
        }
        
    }
}

.navbar-transparent {
    .navbar-nav {
        .nav-item {
            .nav-link:not(.btn) {
                color: $primary-color;
            }
        }
    }

    .navbar-toggler {
        .navbar-toggler-bar{
            background: $white-color !important;
        }
    }
}

.welcome-logo {
    height: 200px;
}