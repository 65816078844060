$gathering-color:                 #CB38B2 !default;
$analysis-color:                 #7092BA !default;
$implementation-color:                 #F66D51 !default;

.limehills-flow {
    
    .flow-image {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        min-height: 45vh;
        max-height: 999px;
        overflow: hidden;
        position: relative;
        width: 100%;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .card-profile {
        margin-top: 0 !important;
    }

    .card-title {
        font-weight: bold;
    }

    .gathering {
        .card-title {
            color: $gathering-color;
        }
    }

    .analysis {
        .card-title {
            color: $analysis-color;
        }
    }

    .implementation {
        .card-title {
            color: $implementation-color;
        }
    }
}

